@font-face {
  font-family: "Poppins";
  src: local("Poppins Semi Bold"), local("Poppins-Semi-Bold"),
    url("Poppins-SemiBold.woff2") format("woff2"),
    url("Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url("Poppins-Medium.woff2") format("woff2"),
    url("Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("Poppins-Regular.woff2") format("woff2"),
    url("Poppins-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Semi Bold"), local("Montserrat-Semi-Bold"),
    url("Montserrat-SemiBold.woff2") format("woff2"),
    url("Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  unicode-range: U+0400-04FF, U+0500-052F, U+2DE0-2DFF, U+A640-A69F;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("Montserrat-Medium.woff2") format("woff2"),
    url("Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  unicode-range: U+0400-04FF, U+0500-052F, U+2DE0-2DFF, U+A640-A69F;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("Montserrat-Regular.woff2") format("woff2"),
    url("Montserrat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0400-04FF, U+0500-052F, U+2DE0-2DFF, U+A640-A69F;
}
