:root {
  /* adaptive screens for media rules*/
  --mobile-screen: 375px;
  --tablet-screen: 768px;
  --desktop-screen: 1280px;

  /* common colors*/
  --auth-bg-color: #bedbb0;
  --auth-input-color: #1f1f1f;
  --accent-color: #bedbb0;
  --without-priority-bg-color: #878787;
  --low-priority-bg-color: #8fa1d0;
  --medium-priority-bg-color: #e09cb5;
  --high-priority-bg-color: #bedbb0;

  /* timig*/
  --timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  /* error */
  --error-red: #e66666;
}
/* theme colors*/
/* dark theme*/
[data-theme="dark"] {
  --primary-text-color: #fff;
  --secondary-text-color: #ffffff80;
  --sidebar-text-color: #fff;
  --btn-text-color: #161616;
  --accent-color: #bedbb0;
  --accent-bell-color: rgba(190, 219, 176, 1);

  --primary-bg-color: #1f1f1f;
  --secondary-bg-color: #121212;
  --navigation-bg-color: #121212;
  --navigation-sec-bg-color: rgba(31, 31, 31, 0.4);
  --navigation-border-color: rgba(255, 255, 255, 0.1);
  --navigation-active-marker: #bedbb0;
  --create-btn-bg-color: #bedbb0;
  --pop-up-bg-color: #151515;
  --header-bg-color: #151515;
  --add-bg-color: #fff;
  --index-label-color: rgba(255, 255, 255, 0.5);
  --divider-color:  rgba(255, 255, 255, 0.1);
  --inner-icon-color:  rgba(255, 255, 255, 1);
  --bg-icon-wrapper-color: rgba(22, 22, 22, 1);
  --taskScrollBase:rgba(255, 255, 255, 0.08);
  --taskScrollHandler:#121212;
  --datepicker-accent-color: #bedbb0;
  --datepicker-bg-color: #1f1f1f;
  --datepicker-primary-text-color: #fff;
  --datepicker-primary-text-color-hover: #ffffff20;
  --datepicker-color-day-name: #ffffff50;
  --datepicker-color-day-disabled: #ffffff20;
  --datepicker-color-day-selected: #1f1f1f;
  --datepicker-color-day-selected-hover: #9bbf8a;
  --datepicker-color-day-keyboard-selected: #ffffff20;
  --datepicker-color-line: #ffffff20;
  --datepicker-color-navigation-icon: #ffffff80;

  --button-icon-bg:#161616;
  --button-icon:#fff;
  --button-icon-bg-invert:#fff;
  --button-icon-invert:#121212;
  --lines:rgba(255, 255, 255, 0.1);
  --board-icons: rgba(255, 255, 255, 0.5);
}

/* light theme*/
[data-theme="light"] {
  --primary-text-color: #161616;
  --secondary-text-color: #161616b2;
  --sidebar-text-color: #161616;
  --btn-text-color: #161616;
  --accent-color: #bedbb0;
  --accent-bell-color: rgba(190, 219, 176, 1);

  --primary-bg-color: #f6f6f7;
  --secondary-bg-color: #fff;
  --navigation-bg-color: #ffffff;
  --navigation-sec-bg-color: rgba(247, 247, 248, 0.4);
  --navigation-border-color: rgba(22, 22, 22, 0.1);
  --navigation-active-marker: #bedbb0;
  --create-btn-bg-color: #bedbb0;
  --pop-up-bg-color: #fcfcfc;
  --header-bg-color: #fcfcfc;
  --add-bg-color: #161616;
  --index-label-color: rgba(22, 22, 22, 0.5);
  --divider-color: rgba(22, 22, 22, 0.1);
  --inner-icon-color:  rgba(255, 255, 255, 1);
  --bg-icon-wrapper-color: rgba(22, 22, 22, 1);
  --taskScrollBase:#E8E8E8;
  --taskScrollHandler:rgba(22, 22, 22, 0.10);
  --datepicker-accent-color: #bedbb0;
  --datepicker-bg-color: #ffffff;
  --datepicker-primary-text-color: #161616;
  --datepicker-primary-text-color-hover: #f0f0f0;
  --datepicker-color-day-name: #16161650;
  --datepicker-color-day-disabled: #16161620;
  --datepicker-color-day-selected: #161616;
  --datepicker-color-day-selected-hover: #9bbf8a;
  --datepicker-color-day-keyboard-selected: #f0f0f0;
  --datepicker-color-line: #16161620;
  --datepicker-color-navigation-icon: #16161680;

  --button-icon-bg:#161616;
  --button-icon:#fff;
  --button-icon-bg-invert:#161616;
  --button-icon-invert:#fff;
  --lines:rgba(22, 22, 22, 0.1);
  --board-icons: rgba(22, 22, 22, 0.5);
}

/* violet theme*/
[data-theme="violet"] {
  --primary-text-color: #161616;
  --secondary-text-color: #161616b2;
  --sidebar-text-color: #fff;
  --btn-text-color: #fff;
  --accent-color: #5255bc;
  --accent-bell-color: rgba(82, 85, 188, 1);

  --primary-bg-color: #ecedfd;
  --secondary-bg-color: #fff;
  --navigation-bg-color: #5255bc;
  --navigation-sec-bg-color: rgba(236, 237, 253, 0.4);
  --navigation-border-color: rgba(255, 255, 255, 0.1);
  --navigation-active-marker: #fff;
  --create-btn-bg-color: #b8bcfd;
  --pop-up-bg-color: #fcfcfc;
  --header-bg-color: #fff;
  --add-bg-color: #5255bc;
  --index-label-color: rgba(22, 22, 22, 0.5);
  --divider-color: rgba(22, 22, 22, 0.1);
  --inner-icon-color:  rgba(22, 22, 22, 1);
  --bg-icon-wrapper-color:  rgba(255, 255, 255, 1);
  --taskScrollBase:#fff;
  --taskScrollHandler:#B8BCFD;
  --datepicker-accent-color: #5255bc;
  --datepicker-bg-color: #ffffff;
  --datepicker-primary-text-color: #161616;
  --datepicker-primary-text-color-hover: #f0f0f0;
  --datepicker-color-day-name: #16161650;
  --datepicker-color-day-disabled: #16161620;
  --datepicker-color-day-selected: #fcfcfc;
  --datepicker-color-day-selected-hover: #353899;
  --datepicker-color-day-keyboard-selected: #f0f0f0;
  --datepicker-color-line: #16161620;
  --datepicker-color-navigation-icon: #16161680;

  --button-icon-bg:#fff;
  --button-icon:#161616;
  --button-icon-bg-invert:#5255BC;
  --button-icon-invert:#fff;
  --lines:rgba(22, 22, 22, 0.1);
  --board-icons: rgba(255, 255, 255, 0.5);
}
/* fonts*/
/*   --font: "Poppins", sans-serif; */

/*adaptive layout with breakpoints example*/
@media screen and (min-width: var(--mobile-screen)) {
  .App {
    background-color: var(--primary-bg-color);
  }
}

@media screen and (min-width: var(--tablet-screen)) {
  .App {
    background-color: var(--secondary-bg-color);
  }
}

@media screen and (min-width: var(--desktop-screen)) {
  .App {
    background-color: var(--navigation-bg-color);
  }
}
