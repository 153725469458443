@import url("./fonts/fonts.css");
@import url("./variables.module.css");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", "Montserrat", serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-bg-color);
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}
.App {
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  height: 100vh;
  max-width: 100%;
  box-sizing: border-box;
}
body {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
p,
img,
ul,
li {
  margin: 0;
  padding: 0;
}
img {
  display: block;
}
li {
  list-style: none;
}
input[type="radio"],
label {
  cursor: pointer;
}
